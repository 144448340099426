export default function bindOurValuesTouchEvents() {
  const touchable = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  if (!touchable) return;

  Array.from(document.querySelectorAll('.ws-values-item')).forEach((el, _, siblings) => {
    const featuredLink = el.querySelector('.ui-featured-link');

    if (featuredLink) {
      featuredLink.ontouchend = e => {
        e.preventDefault();
        siblings.forEach(row => row.classList.remove('reveal'));
        el.classList.add('reveal');
      };
    }
  });
}
